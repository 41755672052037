import React from 'react';
import styled from 'styled-components';
import NavMobile from './NavMobile';
import Nav from './Nav';
import ThemeColor from './ThemeColor';

const HeaderStyled = styled.header`
  background: transparent;
`;

export default function Header() {
  return (
    <HeaderStyled>
      <ThemeColor />
      <NavMobile />
      <Nav />
    </HeaderStyled>
  );
}
