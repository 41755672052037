import React from 'react';
import styled from 'styled-components';
import SocialMenu from './SocialMenu';

const NavSocialStyled = styled.div`
  .footer-subscribe {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (max-width: 1023px) {
    grid-column: 1 / 3;
    .footer-subscribe {
      grid-template-columns: 1fr 1fr;
      grid-gap: 4rem;
      align-items: center;
    }
    input {
      width: 100%;
      min-width: auto;
    }
  }
  @media (max-width: 390px) {
    grid-column: 1 / 1;
    .footer-subscribe {
      grid-template-columns: 1fr;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    margin-right: 2rem;
  }
  a {
    color: var(--white);
    text-decoration: none;
    &:hover {
      color: var(--watermelon);
    }
  }
  a svg {
    vertical-align: middle;
  }
  p {
    margin: 0 0 1rem;
  }
`;

export default function NavSocial() {
  return (
    <NavSocialStyled>
      <div className="footer-social">
        <h4>Follow Bont Online</h4>
        <SocialMenu />
      </div>
      <div className="footer-subscribe">
        <div className="footer-subscribe-intro">
          <h5 className="h6">Join our newsletter</h5>
          <p>
            Sign up to receive advanced notice of our latest products, sales and
            other happenings in the Bont world.
          </p>
        </div>
        <div className="klaviyo-form-WqE7Yv" />
        {/* <form>
          <input type="text" placeholder="Your name" />
          <input type="email" placeholder="Your email" />
          <button type="submit">Sign up</button>
        </form> */}
      </div>
    </NavSocialStyled>
  );
}
