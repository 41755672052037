import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import { FaMoon } from '@react-icons/all-files/fa/FaMoon';
import { FaSun } from '@react-icons/all-files/fa/FaSun';

const ColorStyled = styled.div`
  position: fixed;
  background: var(--backgroundCard);
  right: 0px;
  top: 8rem;
  padding; 1rem;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 15px 0 0 15px;
  z-index: 100;
  span {
    cursor: pointer;
    padding: 1rem;
    svg {
      // fill: var(--watermelon);
      display: block;
    }
  }
`;

export default function ThemeColor() {
  return (
    <ColorStyled>
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <span
            onClick={() =>
              theme === 'dark' ? toggleTheme('light') : toggleTheme('dark')
            }
          >
            {theme === 'dark' ? <FaMoon /> : <FaSun />}
          </span>
        )}
      </ThemeToggler>
    </ColorStyled>
  );
}
